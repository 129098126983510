@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.ProfilePage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
.pageheader{
    color: black !important;
    size: 27px !important;
    padding: 5px;
}
.pageheader a{
    color: black !important;
    size: 27px !important;
   
}
.profile{
    background-color:  #e2e6e7;
    display: flex;
    flex-direction: row;
}
.profilesec1 {
    background-color: white;
    margin:0.8em;
    flex:0.5;

}
.profilesec2 {
    background-color: white;
    margin:0.8em;
    flex:0.5;
}
.profilesec3 {
    background-color: white;
    margin:0.8em;
    flex:0.5;
}
.profilesec4 {
    background-color: white;
    margin:0.8em;
    flex:0.5;
}
.profilehead{
    background-color: #0070b3;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}
.editicon{
    float: right;
    cursor: pointer;
}

.profiledetails{
    padding: 5%;
    font-size: 14px;
}
@media screen and (max-width:768px) {
.profile{
    background-color:  #e2e6e7;
    display: flex;
    flex-direction: column;
}
}
@media screen and (max-width:425px) {
   .profile h5{
        font-size: 17px;
    }
}


.profiletab{
    display: flex;
    flex-direction: column;
    padding: 5%;
    font-family: 'Roboto', sans-serif !important;
  }
  .tablesrow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid rgb(223, 223, 223);
    padding: 6px 0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
  }
  .avatarimages{
      width: 80px;
      height: 80px;
      padding:10%;
  }

form.kyc-details label {
    width: 40%;
}

#profile-summary span {
    text-align: left;
}

#profile-summary * {
    margin-bottom: 0;
}

#profile-summary .caption {
    color: #a3a3a3;
    font-size: 1rem;
}