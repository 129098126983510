@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap');
.DashboardPage{
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif !important;

}
.DashboardPage h3{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}
p{
    font-size: 14px;
}
h5{
  color:#0070b3;
  font-weight: 600;

}
.a1{
    height: 75px;
    background-color: white;
    margin:0.8em;
    flex:0.33;
    padding: 1em;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center !important;
  }
  .overview{
    background-color:  #e2e6e7;
    display: flex;
    flex-direction: row;
    text-align: center;

  }
  .label{
font-size: 17px;


  }
  .icon{
   display: flex;
   justify-content: center;

  }

  .deposit{
    background-color:  #e2e6e7;
    display: flex;
    flex-direction: row;

  }
  .scroll {

    background-color: white;
    margin:0.8em;
    flex:0.7;
    display: flex;
    flex-direction: row;
    }
  .yeye {
     
        background-color: white;
        margin:0.8em;
        flex:0.3;
        padding:2rem;
      }
  .qrcode{
         width: 35% !important;
        color:#0070b3;
        text-align: center;
        margin:5rem 0rem 5rem 1rem;
        line-height: 28px;
}

  .newsfeed{
   
      padding-top:1rem;
      display: flex;
      flex-direction: column;
      position: relative;
      height:380px;
      color: black !important;
        }
      .Daddress{
        color: #111;
      }
      
.depsuccess{
    display: none;
  }
  .singlefeed{
    border:1px solid #d9d9d9;
    padding:2em !important;
    border-radius:5px;
    color: black !important;
  }
  .singlefeed a{

    color: black !important;
  }

  .feed{
  
    overflow:hidden;

  }
  .feedinfo{
    color: gray;
    font-size: 12px;
    float: right;
  }
.recent-news-feed{
  padding:1rem;

}

.flex-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  
  }
  
  .single-chart {
    width: 48%;
    justify-content: space-around ;
  }
  
  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 240px;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  
  
  .circular-chart.blue .circle {
    stroke: #0070b3;
  }
  
  .percentage {
    fill:  #0070b3 ;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
   
  }
  .buysell{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .buy, .sell{
      font-size: 15px;
  }
  .indigo{
    height: 8px;
    width: 8px;
    background-color: #0070b3;
    border-radius: 50%;
    display: inline-block;
  }
  .grey{
    height: 8px;
    width: 8px;
    background-color: #a39e9e;
    border-radius: 50%;
    display: inline-block;
  }
  .green{
    height: 8px;
    width: 8px;
    background-color: #3bb001;
    border-radius: 50%;
    display: inline-block;
  }

  .purple{
    height: 8px;
    width: 8px;
    background-color: rgb(65, 15, 204);
    border-radius: 50%;
    display: inline-block;
  }
  .sky{
    height: 8px;
    width: 8px;
    background-color: #81b9da;
    border-radius: 50%;
    display: inline-block;
  }

  .token{
    background-color:  #e2e6e7;
    display: flex;
    flex-direction: row;
  }
  .chart {
    height: 100%;
    background-color: white;
    margin:0.8em;
    flex:0.7;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: black;
    }

        .tokendetails{
            display: flex;
            flex-direction: row;
            color: black;
          }
          .piechart{
            flex: 0.5;
          }
          .piedetails{
            flex:0.5;
          }.pierow{
            display: flex;
            flex-direction: row !important;
            justify-content: flex-start !important;
            
            
          }
          .media{
            display: flex;
            flex-direction: column !important;
            justify-content: flex-start !important;
            flex:1;
            color: black;
          }
          .label{
            color: black;
          }
          .wallet {
         
            background-color: white;
            margin:0.8em;
            flex:1;
            padding:2rem;
            display: flex;
            flex-direction: column;
         
          }
          .walletdetails{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items:left;
            font-size: 15px;
          }
          .primary{
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
            padding:10px;
          }
          .primary a:hover{
            color: #fff;
            background-color: #0e4988;
            border-color: #0d529c;
           
          }
          .secondary{
            color: #fff;
            background-color: #70737c;
            border-color: #70737c;
            padding:10px;
        
          }
          .secondary a:hover{
            color: #fff;
            background-color: #464649;
            border-color: #5f6064;
            
        
          }
          .btcvalue{
            text-align: center;
          }
          .btctable{
            display: flex;
            flex-direction: column;
            padding: 3px;
            font-family: 'Roboto', sans-serif !important;
          }
          .tablerow{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-top: 1px solid grey;
            padding: 6px 0;
            font-family: 'Roboto', sans-serif !important;
            font-size: 14px;
          }
          .transaction{
              padding:8px;
              text-align: center;
              font-family: 'Roboto', sans-serif !important;
          }

           .overview_menus{
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            background-color: rgb(255, 255, 255);
            position: sticky;
            top: 0;
            text-decoration: none !important;
            display: flex !important;
            flex-direction: row !important;
            color: black !important;
            font-family: 'Roboto', sans-serif !important;
          }
          
        
         .overview_menu a {
            display: flex;
            color: rgb(0, 0, 0);
            padding: 12px 15px 10px 5px;
            text-decoration: none;
            flex-direction: row;
            font-weight: 600;
            font-family: 'Roboto', sans-serif !important;
          }
          
      .overview_menu  a:hover{
            background-color: rgb(253, 252, 252);
            text-decoration: none;
            color: rgb(10, 10, 10) !important;
            font-family: 'Roboto', sans-serif !important;
         border-bottom: 2px solid #0070b3;

          }
         .activelink{
           
         border-bottom: 2px solid #0070b3;

          }

   .break{
     color: white;
   }

   @media screen and (max-width:768px) {

         
    .deposit{
  
      display: flex;
      flex-direction: column;
  
    }
    .token{
  
      display: flex;
      flex-direction: column;
    }
    .walletdetails{
   
      justify-content: space-around;
  
    }
  }
   
@media screen and (max-width:425px) {
.overview{
    display: flex;
    flex-direction: column;
  }
.qrcode{
    width:100% !important;
    color:#0070b3;
    text-align: center;
    margin:1rem 0rem 0rem 0rem;
    line-height: 28px;
}   
.deposit{
    background-color:  #e2e6e7;
    display: flex;
    flex-direction: column;
  }
.scroll {
    display: flex;
    flex-direction: column;
    }
.newsfeed{
           
  padding-top:0em;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 600px;
  color: black !important;
}
.recent-news-feed{
  padding:1rem;
  height: 100% !important;
}
.token{
  background-color:  #e2e6e7;
  display: flex;
  flex-direction: column;
}
.chart {
  height: 100%;
  background-color: white;
  margin:0.8em;
  flex:1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.tokendetails{
  display: flex;
  flex-direction: column;
}
  marquee{
    max-height: 550px;
  }
  .walletdetails{
   
    justify-content: space-around;
    flex-direction: row;
    font-size: 12px;

  }
}




/* dashboard example start */

.welcome_msg{
  background-color:#0F5ABC;
  color:white;
  /* padding: 10px 30px; */
  /* padding-top: 80px; */
}

.welcome_msg p{
  font-size:12px;
  margin-bottom: 0px;
  color:white;
 
}

.title_msg{
  background-color: #0F5ABC;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title_msg p{
  font-size:12px;
  margin-bottom: 0px;
  text-align: start;
}
.table_design{
  border-radius: 10px;
  height: 700px;
  margin: 10px;
  width: auto;
}
.table_design table th{
  font-size:12px;
}
.content{
  margin-left: 250px;
}

.content.active{
  margin-left: 100px;
}

@media screen and (max-width:768px) {
  .content{
      margin-left: 85px;
  }
}
@media screen and (max-width:425px) {
  .content{
      margin-left: 85px;
  }
 
}





