.adminwalletpage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.pageheader a{
    color: black !important;
    size: 27px !important;
}
.transactiontab{
    display: flex;
    justify-content: center;
    align-items: center;
}
.table_transaction{
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
    background-color: rgb(255, 255, 255);
}
.transactionheading{
    background-color: #0070b3;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}
table {
    border-collapse: collapse;
    width: 100%;
   }
td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
}
th{
   background-color:black;
   color:white;
}
.actionbutton{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.acceptbutton{
    background-color: green !important;
    color: white !important;
}
.rejectbutton{
    background-color: red !important;
    color: white !important;
}
.acceptbutton:hover{
    background-color: rgb(25, 143, 25);
}
.rejectbutton:hover{
    background-color: rgb(197, 45, 45);
}
@media screen and (max-width:425px) {
    .table_transaction{
        overflow-x: scroll;      
    }
    .ethereumadd{
    padding: 15px;
    width: 100%;
    }
}
.action2{
    height: 1.2rem;
}
.action1{
    height: 1.2rem;
}
.adminwallet_submit{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}