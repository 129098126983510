.cr-content {

  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

