@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.walletPage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
.walletPage_content{
    padding:5%;
}
.pageheader{
    color: black !important;
    size: 27px !important;
    padding: 5px;
}
.pageheader a{
    color: black !important;
    size: 27px !important;
    
}
.wallet_col{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 0 1rem 0;
}
.wallet_row{
    display: flex;
    flex-direction: column;
    /* height:100px; */
    width:40%;
    background-color: rgb(255, 255, 255);
    border: 1px solid lightgrey !important;
    align-items: center;
    padding:2px;
    justify-content: center;
    font-size: 20px;
}
.wallet_sec{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 900;
    font-size: 2rem;
}
.wallet_sec > * {
    padding: 0.5rem;
}
.wallet_row_description {
    padding: 1rem 0;
}
.deposit_address{
    color: #0070b3 !important;
}
.withdraw_button{
    background-color: #0070b3 !important;
    color: white !important;
    width: auto;
    height: auto;
    padding:1rem;
    text-align: center;
}
.withdraw_btn_area{
    align-items: center;
    display: flex;
    justify-content: center;
}
.wallet_icon{
    font-size: 25px;
    color: #0070b3 !important;
}
@media screen and (max-width:768px) {
    .wallet_row{
        height:150px;
    }
}
@media screen and (max-width:425px) {
    .wallet_col{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }   
     .wallet_row{
        height:120px;
        width: 100%;
        text-align: center;
    }
}
