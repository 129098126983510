
$theme-colors: (
  "primary": #e4dcdc,
  "secondary": #837f7f96,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 222px;
