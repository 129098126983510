@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.ForgotPasswordPage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    background-image: url('../../assets/img/bg/bgimg.jpg');
    background-position: center;
    background-size: cover;
    justify-content: center;

}
*{
    box-sizing: border-box;
}

.ForgotPasswordcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ForgotPasswordform{
    display: flex;
    flex-direction: column;
    height:100%;
    width:35%;
    background-color: black;
    opacity: 0.8;
    border-radius: 5px;
}

.ForgotPasswordimg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.labels{
    color: white;
    float: left;
}

input[type=text] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

button {
  background-color: #0070b3;
  color: white;
  padding: 3% 5%;
  border: none;
  cursor: pointer;
  width: auto;
  border-radius: 5px;
}

button:hover {
  opacity:0.9;
}
.forgotpwd_heading{
    color: white;
    font-family: 'Roboto', sans-serif;
}

.container {
  padding: 16px;
}
@media screen and (max-width:768px) {
    .ForgotPasswordform{

        height:100%;
        width:80%;

    }
  }
@media screen and (max-width:425px) {
    .ForgotPasswordform{
        height:100%;
        width:100%;
    }
   
}


