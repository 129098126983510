.AdminClientListPage{
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif !important;
}
.admin_tablecontent{
    justify-content: normal;
    overflow-x: scroll;
}
.admin_clientlisttable{
    width:63rem;
}
.actionicons{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
}
.blueicon{
    color: cornflowerblue;
}
.greenicon{
    color: green;
}
.redicon{
    color: crimson;
}
.orangeicon{
    color: orange;
}
.blueicon:hover{
    color: rgb(58, 109, 204);
}
.greenicon:hover{
    color: rgb(3, 82, 3);
}
.redicon:hover{
    color: rgb(145, 15, 41);
}
.orangeicon:hover{
    color: rgb(219, 143, 0);
}

input {
    border-bottom: 1px solid rgb(88, 88, 88) !important;
    
  }
  .search_Bar{
    float: right !important;
    display: flex;
    flex-direction: row;
}

div.radio-wrapper input.radio-button {
    width: max-content !important;
    margin-right: 0.5rem;
}

.report_button {
    height: fit-content;
}