@import "variables";

// libraries
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~react-infinite-calendar/styles";

// base
@import "base";
@import "overrides";
@import "extends";
@import "animations";
@import "utils/utils";

// components
@import "components/main";
@import "components/header";
@import "components/sidebar";
@import "components/content";
@import "components/page";
@import "components/widget";
@import "components/search-input";
@import "components/zoom";

// themes
@import "themes/colors";

// demos (only for demo pages)
@import "demos/demos";
