.search-controller{
    color: #fff;
    position: relative;
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
}

.search-controller .search-btn{
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #fff;
    padding: 0px;
}

.search-controller input[type=text]{
    border: 0;
    outline: none;
    height: 35px;
    background: #0F5ABC;
    border-radius: 5px;
    display: block;
    margin: 0px 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    color: #fff;
    border-bottom: 0.5px solid #0f5abc !important;
}

.search-controller input[type=text]::placeholder {
    color: #fff;
  }

.settings{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.settings .notify{
   margin:0px 10px;
}

.settings .mail{
    margin:0px 10px;
}