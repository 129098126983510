@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.LoginPage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    background-image: url('../../assets/img/bg/bgimg.jpg');
    background-position: center;
    background-size: cover;
    justify-content: center;

}
*{
    box-sizing: border-box;
}

.logincontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginform{
    display: flex;
    flex-direction: column;
    height:100%;
    width:35%;
    background-color: black;
    opacity: 0.8;
    border-radius: 5px;
}

.loginimg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.labels{
    color: white;
    float: left;
}

input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

button {
  background-color: #0070b3;
  color: white;
  padding: 3% 5%;
  border: none;
  cursor: pointer;
  width: auto;
  border-radius: 5px;
}

button:hover {
  opacity:0.9;
}


.container {
  padding: 16px;
}

span.password {
  float: right;

}
.forgotpwd{
  color: white !important;
}
.newregister{
  float: left;
}
@media screen and (max-width:768px) {
    .loginform{

        height:100%;
        width:80%;

    }
  }
@media screen and (max-width:425px) {
    .loginform{
        height:100%;
        width:100%;
    }
    span.password {
        display: block;
        float: none;
     }
   
}


