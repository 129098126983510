 .top-section{
    position: relative;
    padding: 0px 10px;
    height: 72px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    background-color: #0F5ABC;
    color:white;
}
 .top-section .logo{
    width: 64px;
    height: 44px;
    overflow: hidden;
    display: inline-block;
}
.top-section .logo img{
    max-width: 100%;
    max-height: 100%;
}

.top-section .toggle-menu-btn{
    color: #666;
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
}

.top-section .toggle-menu-btn{
    right: -50px;
}
.clientName
{
    display: flex;
    margin: 10px 0px;
    flex-wrap: nowrap;
    align-items: center;
}
.clientName img{
 width:40px;
 height:40px;
 margin:10px;
 border-radius:50px !important;
}
.clientName .user-info h5,p{
    color:black;
    margin-bottom:0px;
    font-size:14px;
}