@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.KYCVerification1{
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;

}
.kycheading{
    text-align: center;
}
.footer{
    justify-content: center;
    align-items: center;
    display: flex;
}
.LoginHeader{
    background-image: linear-gradient(rgba(0, 0, 0, 0.427),rgba(0, 0, 0, 0.4)) ,url('../../assets/img/bg/signup.jpg');
    background-position: center;
    background-size: cover;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 45px;
    font-weight: 700;
    color: white;
    padding:5%;
}
*{
    box-sizing: border-box;
}

.container {
    width: 100%;
  }
  
  .progressbar {
    counter-reset:step;
  }
  .progressbar li {
    list-style: none;
    display: inline-block;
    width: 25%;
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  .progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 50px;
    height: 50px;
    line-height : 40px;
    border: 5px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff;
  }
  .progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ddd;
    top: 25px;
    left: -50%;
    z-index : -1;
  }
  .progressbar li:first-child:after {
    content: none;
  }

  .progressbar li.active {
    color: #0070b3;
  }
  .progressbar li.active:before {
    border-color: #0070b3;
    border: 5px solid #0070b3;
  } 

  /*.progressbar li.active + li:after {
    background-color: #0070b3;
  }
*/

  .progressbar li.complete {
    color: #93c9e4;
  }
  .progressbar li.complete:before {
    border-color: #93c9e4;
    border: 5px solid #93c9e4;
  } 

  .progressbar li.complete + li:after {
    background-color: #93c9e4;
  }

  .progressbar li.incomplete {
    color: lightgrey;
  }
  .progressbar li.incomplete:before {
    border-color: lightgrey;
    border: 5px solid lightgrey;
  } 

  .progressbar li.incomplete + li:after {
    background-color: lightgrey;
  }

  input[type=text]{
    width: 100%;
    padding: 12px;
    resize: vertical;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  .row{
   display:flex;
   align-items:center;
   justify-content:center;
   text-align: center;
  
  }
  button {
    background-color: #0070b3;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  
  }
  
  button:hover {
    background-color: #61a3c9;
  }
  
  .formcontainer {
    border-radius: 5px;
    background-color: #ffffff;
    padding:3%;
    width:55%;
    border: 2px solid #8ec3e2;

  }
  .formsection{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2%;
  }
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  label{
      float: right;
  }
  .register1_formfield1{
    width: 100% !important;
    height: 2rem;
    border-radius: 4px !important;
    border: 1px solid lightgray !important;
  }
  .label{
    margin-top: 5% !important;
  }
  @media screen and (max-width: 768px) {
    .formcontainer {
 
      width:100%;

  
    }
    .col-25 {
        float: left !important;
        width: 100%;
        padding: 2% 5%;
      }
      
      .col-75 {
        float: left;
        width: 100%;
      padding: 2% 5%;
      }
      label{
        float: left;
    }
    .col-25, .col-75, input {
       margin-top: 0;
    }
    .progressbar li {
        list-style: none;
        display: block;
        width: 100%;
        position: relative;
        text-align: center;
        cursor: pointer;
        padding: 2% 8% 2% 0%;
      }
      .progressbar li.complete + li:after {
        display: none;
      }
      .progressbar li.active + li:after {
        display: none;
      }
      .progressbar li.incomplete + li:after {
        display: none;
      }

  }

  .field-icon {
    float: right;
    margin-left: -40px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }

  .skip{
    background-color: rgb(37, 132, 192) !important;
    color: white !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .submit_button{
    background-color: #0070b3;
    color: white !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
