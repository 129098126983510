@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.AdminClientEdit{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
.pageheader{
    color: black !important;
    size: 27px !important;
    padding: 5px;
}
.pageheader a{
    color: black !important;
    size: 27px !important;
}
.clientedit_change{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.clienteditheader{
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
    background-color: rgb(255, 255, 255);
    border: 1px solid lightgrey !important;
}
.clientedit_header{
    background-color: #0070b3;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.adminedit{
    color: white;
}
.adminedit:hover{
    color: rgb(214, 209, 209);
    text-decoration: none;
}
.clienteditbody{
    display: flex;
    flex-direction: row;
}
.clientEditbody1{
    flex: 0.5;
    display: flex;
    flex-direction: column;
}
.clienteditbody2{
    flex: 0.5;
    display: flex;
    flex-direction: column;
}
.clienteditbody3{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.clientsdata{
    padding:5% ;
    flex: 0.5;
}
.choosefile1{
    flex: 0.5;
    padding: 0 2%;
}
.choosefile2{
    padding: 0 2%;
    flex: 0.6;
}
.choosefile3{
    flex: 0.2;
    padding: 0 2%;
}
.clientdatainfo{
    background-color: #f0f3f6;
}
.statuspending{
    color: red;
}
.statusactive{
    color: green;
}

.clienteditbody4{
    flex:1;
    display: flex;
    flex-direction: column;
}
.clienteditbody5{
    display: flex;
    flex-direction: row ;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
}
.clienteditbody6{
    display: flex;
    flex-direction: row ;
    align-items: flex-end;
    justify-content: space-between;
    padding: 2%;
    border-bottom: 1px solid rgb(207, 207, 207);
}
.adminstatuspending1{
    color: red;
}
.proofsize{
    width: 50%;
}
.adminformsection{
    padding: 2%;
}
.sendmail{
    color: #fff;
    border-radius: 6px;
    background-color: #6f42c1;
    border-color: #643ab0;
    padding:2%;
    text-decoration: none;
}
.kycapprove{
    color: #fff;
    background-color: #3bb001;
    border-color: #3bb001;
    border-radius: 50px;
    padding: 2%;
}

@media screen and (max-width:768px) {
    .clienteditbody1{
        flex-direction: column;
        flex: 1;
    }
    .clienteditbody{
        flex-direction: column;
    }
    .clienteditbody3{
        flex-direction: column;
    }
 
}
@media screen and (max-width:425px) {
    .clienteditbody6{
        flex-direction: column ;
        justify-content: space-between;
    }
    .clienteditbody5{
        display: flex;
        flex-direction: column ;
        justify-content: space-between;
    }
}