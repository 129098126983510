@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.depositPage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
*{
    box-sizing: border-box;
}
.pageheader{
    color: black !important;
    size: 27px !important;
    padding: 5px;
}
.pageheader a{
    color: black !important;
    size: 27px !important;
}
.ethereum{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ethereumqr{
    display: flex;
    flex-direction: column;
    height:280px;
    width:55%;
    background-color: rgb(255, 255, 255);
    border: 1px solid lightgrey !important;
}
.ethereumadd{
    background-color: #0070b3;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}
.qrimg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.Daddress{
    display: flex;
    justify-content: center;
    align-items: center;
}
.deposit_address{
    color: #0070b3 !important;
}
@media screen and (max-width:768px) {
    .ethereumqr{

        height:100%;
        width:80%;

    }
  }
@media screen and (max-width:425px) {
    .ethereumqr{
        height:100%;
        width:100%;
    }
    .Daddress{
        display:inline-block;
        padding:5%;
    }
}