@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.AdminClientView{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
.pageheader{
    color: black !important;
    size: 27px !important;
    padding: 5px;
}
.pageheader a{
    color: black !important;
    size: 27px !important;
}
.clientview_change{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.clientviewheader{
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
    background-color: rgb(255, 255, 255);
    border: 1px solid lightgrey !important;
}
.clientview_header{
    background-color: #0070b3;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.adminview{
    color: white;
}
.adminview:hover{
    color: rgb(214, 209, 209);
    text-decoration: none;
}
.clientviewbody{
    display: flex;
    flex-direction: row;
}
.clientviewbody1{
    flex: 0.5;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.clientviewbody2{
    flex: 0.5;
    display: flex;
    flex-direction: column;
}
.clientviewbody3{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.clientdata{
    padding:5% 8%;
    flex: 0.5;
}
.clientdatainfo{
    background-color: #f0f3f6;
}
.statuspending{
    color: red;
}
.statusactive{
    color: green;
}

.clientviewbody4{
    flex:1;
    display: flex;
    flex-direction: column;
}
.clientviewbody5{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.statuspending1{
    color: red;
    text-align: center;
}
.proofsize{
    width: 40%;
}
@media screen and (max-width:768px) {
    .clientviewbody1{
        flex-direction: column;
        flex: 1;
    }
    .clientviewbody{
        flex-direction: column;
    }
    .clientviewbody3{
        flex-direction: column;
    }
}