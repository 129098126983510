* {
    box-sizing: border-box;
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: sub;
}
a {
    text-decoration: none;
    color: rgba(105, 105, 105, .88);
    transition: color 0.3s ease;
}
a:hover {
    color: #fc6e51;
}
body {
    font-family: 'Roboto', sans-serif;
    background: #f9f9f9;
}
.flexslider {
    border: none;
    box-shadow: none;
}
.flex-direction-nav a {
    background: url(https://flexslider.woothemes.com/images/bg_direction_nav.png) no-repeat 0 0;
    width: 30px;
    height: 30px;
    margin: -20px 0 0;
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    /* text-indent: -9999px; */
    opacity: 0;
    -webkit-transition: all 0.3s ease;
}
.flex-direction-nav a:before {
    content: '';
}
.flex-direction-nav .flex-next {
    background-position: 100% 0;
    right: -36px;
    text-align: left;
}
.flex-direction-nav .flex-next:before {
    content: '';
}
.heading h3 {
    font-weight: 300;
    color: #888;
}
.nav ul {
    margin: 0;
    padding: 0;
}
.nav li {
    list-style: none;
    margin: 0;
}
.btn-navbar {
    margin-top: 10px;
    margin-bottom: 10px;
}
.btn-navbar li {
    float: left;
    margin: 0 5px;
}
.btn-navbar li:first-child {
    margin-left: 0;
}
/* .btn {
    display: inline-block;
    padding: 6px 14px;
    background: #f2f2f2;
    border-radius: 3px;
    font-size: 0.8125em;
    color: #b9bcbc;
    transition: background 0.3s ease, color 0.3s ease;
}
.btn:hover {
    background: #e5e5e5;
    color: #929797;
} */
/* .btn.btn-primary {
    background: #418003;
    color: #fff;
}
.btn.btn-primary:hover, .btn.btn-primary.active {
    background: #94cd5a;
}
.btn.btn-primary-outline {
    color: #418003;
    border: 1px solid #418003;
}
.btn.btn-secondary {
    background: #6ad1dd;
    color: #fff;
}
.btn.btn-secondary:hover, .btn.btn-secondary.active {
    background: #55cbd8;
} */
.btn.btn-tertiary {
    background: #fc6e51;
    color: #fff;
}
.btn.btn-tertiary:hover, .btn.btn-tertiary.active {
    background: #fc5938;
}
.btn.btn-quaternary {
    background: #48cfad;
    color: #fff;
}
.btn.btn-quaternary:hover, .btn.btn-quaternary.active {
    background: #35c9a4;
}
.btn.btn-white {
    background: #fff;
}
.btn.btn-white:hover {
    background: #fafafa;
}
.btn.btn-grey {
    background: #ddd;
}
.btn.btn-grey:hover {
    background: #ccc;
}
.btn.btn-large {
    padding: 12px 28px;
}
.btn.btn-caps {
    text-transform: uppercase;
}
.btn.btn-block {
    display: block;
    width: 100%;
    text-align: center;
}
.btn-group {
    position: relative;
    display: inline-block;
    margin: 10px 0;
}
.btn-group:before {
    content: attr(data-grouptype);
    color: #999;
    display: block;
    font-size: 0.8125em;
    width: 19px;
    height: 19px;
    padding: 5px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 6px;
    margin-left: -18px;
}
.btn-group a + a {
    margin-left: 5px;
}
.btn-navbar .btn {
    font-size: 0.6875em;
}
.btn-navbar .active {
    background: #6ad1dd;
    color: #fff;
}
.btn-navbar .active:hover {
    background: #55cbd8;
}
.post {
    font-family: 'Source Sans Pro', sans-serif;
    box-shadow: 0 2px 0 rgba(204, 204, 204, .15);
    margin-bottom: 20px;
}
.post .post-image {
    display: block;
}
.post .icon-attachment {
    color: #fff;
}
.post .details {
    position: relative;
    border: 1px solid #C5BEBE;
    text-align: center;
    padding: 0 10px 10px;
    height: 80px;
    /* width: 220px; */
    margin: 30px 5px;
}
.post .details h2 {
    font-size: 0.90625em;
    font-weight: 600;
    color: #696969;
    margin: 0;
    padding: 5px 0;
}
.post .details p {
    font-size: 0.8125em;
    color: #aaa;
    margin: 0 0 5px;
    padding: 0;
}
.post .details .intents {
    text-align: right;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0.6875em;
    color: #aaa;
    line-height: 14px;
}
.post .details .intents [class*="icon-"] {
    font-size: 14px;
}
.post .details .intents .count {
    line-height: 14px;
    display: inline-block;
    position: relative;
    top: -3px;
    margin: 0 5px;
}
.post .details .intents .icon-star {
    color: #fc6e51;
}
.post .actions {
    height: 20px;
}
.post .actions [class*="icon-"] {
    font-size: 18px;
    font-weight: normal;
}
.post .actions .btn-round {
    position: absolute;
    /* border-radius: 50%; */
    padding: 4px 0;
    width: 58px;
    height: 46px;
    top: -24px;
    /* right: 68px; */
    left: 25px;
    text-align: center;
    border: none;
}
.post.post-blog .details {
    /* font-family: 'Open Sans', sans-serif; */
    padding: 10px 20px;
    text-align: left;
}
.post.post-blog .details h2 {
    font-size: 1.375em;
    font-weight: 300;
}
.post.post-blog .details strong {
    color: #888;
}
.post.post-product .details {
    text-align: left;
    padding: 0 20px 20px;
}
.post.post-product .product-actions {
    margin: 20px 0 0;
}
.post.post-product .download-count {
    margin: 0 20px;
}
.slider img {
    border-radius: 3px;
}
.widget {
    font-family: 'Source Sans Pro', sans-serif;
    box-shadow: 0 2px 0 rgba(204, 204, 204, .15);
    margin-bottom: 20px;
    border-radius: 0 0 3px 3px;
    background: #fff;
    position: relative;
}
.widget .header {
    height: 100px;
    border-radius: 3px 3px 0 0;
    font-size: 1.25em;
    font-weight: 600;
    color: #fff;
    line-height: 100px;
    padding: 0 20px;
    overflow: hidden;
    position: relative;
}
.widget .header h2 {
    margin: 0;
}
.widget .header h3 {
    font-size: 0.6875em;
    text-transform: uppercase;
    margin: 0;
    line-height: 100px;
    text-align: center;
    font-weight: 300;
}
.widget .header.header-tertiary {
    background: #fc6e51;
}
.widget .header.header-quaternary {
    background: #48cfad;
}
.widget .header.header-twitter {
    background: #55acee;
}
.widget .header .background-icon [class*="icon-"] {
    color: rgba(0, 0, 0, .05);
    position: absolute;
    top: -20px;
    left: -40px;
    font-size: 7.5em;
}
.widget .actions {
    height: 20px;
    text-align: center;
}
.widget .actions [class*="icon-"] {
    font-size: 18px;
    font-weight: normal;
}
.widget .actions .btn-round {
    position: relative;
    border-radius: 50%;
    padding: 10px 0;
    width: 38px;
    height: 38px;
    top: -19px;
    text-align: center;
}
.widget .actions .btn-twitter {
    background: #55acee;
    color: #fff;
}
.widget .actions .btn-twitter:hover {
    background: #3ea1ec;
}
.weather .weather-icon {
    position: absolute;
    right: 20px;
    top: 35px;
    font-size: 1.25em;
    line-height: 1em;
}
.weather .weather-icon-shadow {
    position: absolute;
    color: rgba(0, 0, 0, 0.05);
    font-size: 6.25em;
    top: -20px;
    right: -20px;
    -webkit-transform: rotate(-20deg);
}
.weather ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.weather .day {
    padding: 20px 30px;
    border-bottom: 1px solid #f2f2f2;
    color: #888;
    font-family: 'Source Sans Pro', sans-serif;
}
.weather .day:last-child {
    border-bottom: none;
}
.weather .image {
    display: block;
    float: left;
    margin-right: 10px;
}
.weather .day-title {
    text-transform: uppercase;
    color: #555;
    font-weight: 600;
}
.weather .day-icon {
    line-height: 50px;
    color: #aaa;
    font-size: 1.25em;
}
.twitter ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.twitter li {
    border-bottom: 1px solid #f2f2f2;
    padding: 20px 30px;
}
.twitter li:last-child {
    border-bottom: none;
}
.twitter .avatar {
    margin-right: 10px;
}
.twitter .avatar img {
    border-radius: 50%;
}
.twitter .message {
    display: inline-block;
    margin-top: 12px;
    font-size: 0.8125em;
    color: #999;
}
.twitter .message strong {
    color: #555;
}
.storage .meta {
    text-align: right;
    text-transform: uppercase;
    font-size: 0.875em;
    font-weight: 300;
    color: #888;
    padding: 10px 20px 20px;
}
.storage ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.storage li {
    padding: 20px 30px 20px 20px;
    border-bottom: 1px solid #f2f2f2;
    color: #999;
}
.storage li:last-child {
    border-bottom: none;
}
.storage li:before {
    content: '';
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 5px;
    margin-right: 10px;
}
.storage li.active {
    color: #555;
}
.storage li.active:before {
    content: '\e60c';
    font-family: icomoon;
    background: #48cfad;
    color: #fff;
    box-sizing: border-box;
    padding: 2px;
}
.tags {
    margin: 10px 0;
}
.social {
    margin-bottom: 10px;
}
.social [class*="icon-"] {
    margin-left: 10px;
    display: inline-block;
}
.social .btn {
    color: #fff;
}
.social .btn-facebook {
    background: #3b5998;
    color: #fff;
}
.social .btn-facebook:hover, .social .btn-facebook.active {
    background: #344e86;
}
.social .btn-twitter {
    background: #55acee;
    color: #fff;
}
.social .btn-twitter:hover, .social .btn-twitter.active {
    background: #3ea1ec;
}
.social .btn-dribbble {
    background: #ea4c89;
    color: #fff;
}
.social .btn-dribbble:hover, .social .btn-dribbble.active {
    background: #e7357a;
}
.feedback .details {
    padding: 20px;
    text-align: left;
}
.feedback .details p {
    font-size: 0.875em;
}
.feedback .details .image {
    float: left;
    margin-right: 10px;
}
.feedback .details .meta {
    text-align: right;
    color: #ccc;
    font-size: 1.25em;
}
.feedback .details .meta .icon-star {
    color: #fecd5f;
}
.alert {
    background: #fcf8e3;
    padding: 15px 0 15px 15px;
    margin-bottom: 10px;
    font-size: 0.8125em;
    color: #888;
    border-radius: 3px;
    position: relative;
}
.alert .icon-checkmark, .alert .icon-exclamation {
    display: inline-block;
    margin-right: 10px;
}
.alert .close {
    background: rgba(0, 0, 0, .1);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 3px 3px 0;
    padding: 10px 30px;
}
.alert .close [class*="icon-"] {
    font-size: 1.875em;
    color: #fff;
}
.alert.alert-success {
    background: #a1d36e;
    color: #fff;
}
.alert.alert-info {
    background: #6ad1dd;
    color: #fff;
}
.verified:before {
    content: '\e60c';
    font-family: icomoon;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    background: #a1d36e;
    color: #fff;
    box-sizing: border-box;
    padding: 3px;
}
footer {
    height: 100px;
}

.CardList{
    display: inline-flex;
    flex-wrap: wrap;
    /* display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center; */
    /* margin: 0px 10px; */
    /* padding: 0px 10px; */
}
.icon_img{
    width: 30px;
    margin-top: 5px;
}
