.cr-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -$cr-sidebar-width;
  z-index: 5;
  width: $cr-sidebar-width;
  font-weight: 200;
  font-size: 14px !important;
  color: #fff;
  background-position: center center;
  //background-size: cover;
  transition: left 0.1s ease-in;

  + .cr-content {
    transition: margin 0.1s ease-in;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
  }

  &::before {
    background: #000;
    opacity: 1;
  }

  &::after {
    z-index: 3;
    // sublime light
    background: white; /* fallback for old browsers */
    // background: linear-gradient(to bottom, #000000, #000000);
    opacity: 1;
  }

  &[data-image]:after {
    opacity: 1;
  }

  &__content {
    position: relative;
    z-index: 4;
    height: calc(100vh - 20px);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: #ffffff;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  &__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__nav-item-icon {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.5rem;
  }

  &--open {
    left: 0;
    transition: left 0.2s ease-in;

    + .cr-content {
      margin: 0 0 0 $cr-sidebar-width;
      transition: margin 0.2s ease-in;
    }
  }

  .nav {
    // margin-top: 20px;

    .nav-item {
      margin: 5px 0px;
      color: theme-color(light);
      border-radius: 4px;
      opacity: 0.86;
    }

    .nav-link {
      // @include media-breakpoint-down(md) {
      //   padding: .5rem;
      // }
      // color: theme-color(light);
      color: #857a7a;

      &:hover {
        // background: rgba(theme-color(light), 0.15);
        background-color: #1269db;
        color: white;
      }
    }
  }
}
