@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.PwdPage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
.pageheader{
    color: black !important;
    size: 27px !important;
    padding: 5px;
}
.pageheader a{
    color: black !important;
    size: 27px !important;
}
.password_change{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.pass_header{
    display: flex;
    flex-direction: column;
    height:100%;
    width:60%;
    background-color: rgb(255, 255, 255);
    border: 1px solid lightgrey !important;
}
.password_header{
    background-color: #0070b3;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;

}
.password_body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding:5%;
}

.submit_button{
    background-color: #0070b3 !important;
    color: white;
    width: auto;
    height: auto;
    padding:0.5rem;
    text-align: center;
 
}

.submit_button:disabled {
    background-color: #c1c1c1 !important;
    cursor: unset;
}

.client_formpassword{
    width: 100% !important;
    height: 1.6rem;
    border-radius: 2px !important;
    border:transparent ;
  }
@media screen and (max-width:768px) {
    .pass_header{
        width:90%;
    }
}
@media screen and (max-width:425px) {
    .pass_header{
        width:95%;
    }
}