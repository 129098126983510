.transactionPage{
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}
.pageheader a{
    color: black !important;
    size: 27px !important;
   
}
.transactiontab{
    display: flex;
    justify-content: center;
    align-items: center;
}
.table_transaction{
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
    background-color: rgb(255, 255, 255);  
}
.ethereumadd{
    background-color: #0070b3;
    color: white;
    padding: 15px;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}
table {
    border-collapse: collapse;
    width: 100%;
   }
td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
}
th{
   background-color:black;
   color:white;
}
@media screen and (max-width:425px) {
    .table_transaction{
        overflow-x: scroll;      
    }
    .ethereumadd{
    padding: 15px;
    width: 100%;
    }
}